import { PaymentMethod } from '@pedix-workspace/utils';

export const getActivePaymentMethods = (paymentMethods?: PaymentMethod[]): PaymentMethod[] => {
  if (!Array.isArray(paymentMethods)) {
    return [];
  }

  const activePaymentMethods = paymentMethods.filter(pm => pm.active);

  const paymentMethodsWithLink = activePaymentMethods.filter(pm => pm.hasLink);
  const paymentMethodsWithoutLink = activePaymentMethods.filter(pm => !pm.hasLink);

  const sortedPaymentMethodsWithLink = paymentMethodsWithLink.sort((a, b) => {
    return getOrder(a.type) - getOrder(b.type);
  });

  const sortedActivePaymentMethods = [...paymentMethodsWithoutLink, ...sortedPaymentMethodsWithLink];

  return sortedActivePaymentMethods.map(paymentMethod => {
    if (paymentMethod.hasLink) {
      switch (paymentMethod.type) {
        case 'ZENRISE_LINK':
          paymentMethod.name = 'Pago online (T. débito / crédito)';
          break;
        case 'MERCADOPAGO_LINK':
          paymentMethod.name = 'Pago online (T. débito / crédito / Mercadopago)';
          break;
        case 'UALA_LINK':
          paymentMethod.name = 'Pago online (T. débito / crédito / Ualá)';
          break;
      }
    }
    return paymentMethod;
  });
};

function getOrder(type: string): number {
  switch (type) {
    case 'ZENRISE_LINK':
      return 1;
    case 'MERCADOPAGO_LINK':
      return 2;
    case 'UALA_LINK':
      return 3;
    default:
      return 4;
  }
}
