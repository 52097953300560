import { MessageRenderParams, MessageRenderType } from '../order-message.types';
import {
  OrderMessageRendererBase,
  OrderMessageRendererKeyValueParams,
  OrderMessageRendererNestedFn,
  OrderMessageRendererTextModifiers,
} from './order-message-renderer-base.class';

const INDENT_BY_LEVEL = {
  0: '',
  1: '    ',
  2: '        ',
};

export class OrderMessageRendererWhatsapp extends OrderMessageRendererBase {
  private buffer: string[];

  constructor(protected renderParams: MessageRenderParams) {
    super(renderParams);

    this.buffer = [];
  }

  getType(): MessageRenderType {
    return 'whatsapp';
  }
  getIdentationByLevel(identationLevel: number): string {
    return INDENT_BY_LEVEL[identationLevel];
  }
  getCurrencyOptions() {
    return {
      forceDecimals: false,
      separateSymbol: false,
    };
  }
  getSystemMessageIcon(): string {
    return '►';
  }

  renderLineBreak() {
    this.buffer.push('\n');

    return this;
  }
  renderEmptyLine() {
    this.buffer.push('\n');

    return this;
  }
  renderLine(
    param1: string | OrderMessageRendererNestedFn,
    modifiers?: OrderMessageRendererTextModifiers,
  ) {
    if (typeof param1 === 'function') {
      param1();
    } else {
      this.renderText(param1, modifiers);
    }
    this.renderLineBreak();

    return this;
  }
  renderText(text: string, modifiers?: OrderMessageRendererTextModifiers) {
    const textParts = [];

    if (modifiers?.indentLevel !== undefined) {
      textParts.push(this.getIdentationByLevel(modifiers.indentLevel));
    }
    if (modifiers?.bold !== undefined) {
      textParts.push('*');
    }
    if (modifiers?.italic !== undefined) {
      textParts.push('_');
    }
    if (modifiers?.monospace !== undefined) {
      textParts.push('```');
    }

    textParts.push(modifiers?.uppercase ? text.toUpperCase() : text);

    if (modifiers?.monospace !== undefined) {
      textParts.push('```');
    }
    if (modifiers?.italic !== undefined) {
      textParts.push('_');
    }
    if (modifiers?.bold !== undefined) {
      textParts.push('*');
    }

    this.buffer.push(textParts.join(''));

    return this;
  }
  renderKeyValueLine({
    keyText,
    keyModifiers,
    valueText,
    valueModifiers,
  }: OrderMessageRendererKeyValueParams) {
    this.renderText(`${keyText}:`, keyModifiers);
    this.renderText(' ');
    this.renderText(valueText, valueModifiers);
    this.renderLineBreak();

    return this;
  }
  renderSectionTitle(text: string) {
    this.renderLine(text, { bold: true, uppercase: true });

    return this;
  }
  renderObservations(
    observations: string,
    modifiers?: Pick<OrderMessageRendererTextModifiers, 'indentLevel'>,
  ): OrderMessageRendererBase {
    this.renderText('*: ', { indentLevel: modifiers?.indentLevel });
    this.renderText(`"${observations}"`, { italic: true });
    this.renderLineBreak();

    return this;
  }
  renderSystemMessage(
    message: string,
    modifiers?: Pick<OrderMessageRendererTextModifiers, 'indentLevel'>,
  ): OrderMessageRendererBase {
    this.renderText(`${this.getSystemMessageIcon()} `, { indentLevel: modifiers?.indentLevel });
    this.renderText(message, { italic: true });
    this.renderLineBreak();

    return this;
  }
  renderColumns(
    columnValues: string[],
    _columnSizes?: number[],
    modifiers?: OrderMessageRendererTextModifiers,
  ): OrderMessageRendererBase {
    this.renderLine(columnValues.join('``` | ```'), modifiers);

    return this;
  }
  renderSeparator(): OrderMessageRendererBase {
    this.renderLine('- - - - - - - -');

    return this;
  }
  output() {
    return this.buffer.join('');
  }
}
