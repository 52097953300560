import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  OnInit,
  inject,
  PLATFORM_ID,
  signal,
  input,
  computed,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

declare global {
  interface Window {
    lazySizes: unknown;
  }
}

import { IconLoadingComponent } from '@pedix-workspace/angular-ui-icons';
import { NgClass, NgStyle, isPlatformBrowser } from '@angular/common';
import { toObservable } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, skip } from 'rxjs';

const errorImage = 'https://cdn.pedix.app/no-product-image.png?alt=media&size=600';

/**
 * IMPORTANT: this component uses "lazySizes" library under the hood to ensure lazy-loading behavior
 * - It doesn't require any initialization; adding "lazyload" class to image is enough
 * - We once tried adding the npm import here, but it affected other 3rd party library such as ngx-colors, so it was decided to load it externally with an script tag in any requiring project
 * - lazySizes script URL: https://cdn.jsdelivr.net/npm/lazysizes@5.3.2/lazysizes.min.js
 */
@UntilDestroy()
@Component({
  selector: 'pxw-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, IconLoadingComponent, NgStyle],
})
export class ImageComponent implements OnInit {
  private platformId = inject(PLATFORM_ID);

  src = input.required<string | null>();
  lazyLoad = input<boolean>(false);

  @Input() objectFit: 'fill' | 'contain' | 'cover' | 'scale-down' | 'none' = 'contain';
  @Input() aspectRatio: number | null = null;
  @Input() width: number | null = null;
  @Input() height: number | null = null;
  @Input() alt = '';
  @Input() loader: 'sm' | 'lg' | 'none' = 'lg';

  isLoading = signal<boolean>(false);
  hasError = signal<boolean>(false);

  imageUrl = computed(() => (this.hasError() ? errorImage : this.src()));

  @HostBinding('style.aspect-ratio') get aspectRatioStyle() {
    return this.aspectRatio;
  }

  @HostBinding('style.width.px') get widthStyle() {
    return this.width;
  }

  @HostBinding('style.height.px') get heightStyle() {
    return this.height;
  }

  constructor() {
    toObservable(this.src)
      .pipe(distinctUntilChanged(), skip(1), untilDestroyed(this))
      .subscribe(() => {
        this.isLoading.set(true);
        this.hasError.set(false);
      });
  }

  ngOnInit(): void {
    if (this.lazyLoad()) {
      this.isLoading.set(true);
    }

    if (isPlatformBrowser(this.platformId) && typeof window.lazySizes === 'undefined') {
      console.warn(
        'Lazysizes library is not loaded; add https://cdn.jsdelivr.net/npm/lazysizes@5.3.2/lazysizes.min.js to index.html file',
      );
    }
  }

  onImageLoad() {
    this.isLoading.set(false);
  }

  onImageError() {
    this.isLoading.set(true);
    this.hasError.set(true);
  }
}
