import { Component, Input } from '@angular/core';

import { TranslocoDirective } from '@ngneat/transloco';
import { CurrencyFormatPipe } from '@pedix-workspace/pedixapp-shared-pipes';
import { EndOrderDetails } from '@pedix-workspace/utils';

export type EndOrderTotals = Pick<
  EndOrderDetails,
  | 'totalAmount'
  | 'totalAmountWithDiscount'
  | 'totalProductDiscount'
  | 'couponDiscount'
  | 'deliveryCost'
  | 'outOfDeliveryZone'
  | 'paymentMethodExtraCharge'
  | 'paymentMethodDiscount'
  | 'foodBankDonationAmount'
  | 'finalAmount'
>;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-order-total-details',
  templateUrl: './total-details.component.html',
  styleUrls: ['./total-details.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, CurrencyFormatPipe],
})
export class OrderTotalDetailsComponent {
  @Input() orderTotals: EndOrderTotals;
}
