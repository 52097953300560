<ng-container *transloco="let t">
  <div class="working-hours-dialog">
    @if (workingDays.customizeHoursByDay) {
      @for (dayOfWeek of workingDays.selectedDaysOfWeek; track dayOfWeek) {
        <p>
          <strong>{{ DAY_OF_WEEK_NAMES[dayOfWeek] }}: </strong>
          <span
            >{{
              getWorkingHoursMessage(
                workingDays.daysOfWeekConfig[dayOfWeek].primaryWorkingHours,
                workingDays.daysOfWeekConfig[dayOfWeek].secondaryWorkingHours
              )
            }}.</span
          >
        </p>
      }
    } @else {
      <p>
        <strong>{{ t('workingHours.daysText') }}: </strong>{{ getDaysOfWeekMessage() }}
      </p>
      <p>
        <strong>{{ t('workingHours.workingHoursText') }}: </strong>
        <span
          >{{
            getWorkingHoursMessage(
              workingDays.primaryWorkingHours,
              workingDays.secondaryWorkingHours
            )
          }}.</span
        >
      </p>
    }
  </div>
</ng-container>
