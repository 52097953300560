@if (loader !== 'none') {
  <div
    [ngClass]="{ loading: isLoading() }"
    class="ui-loader ui-loader--local ui-loader--size-{{ loader }}"
  >
    <pxw-icon icon="loading" [spin]="true"></pxw-icon>
  </div>
}
<img
  [ngClass]="{ lazyload: lazyLoad() && isLoading(), lazyloading: isLoading() }"
  [attr.src]="lazyLoad() ? null : imageUrl()"
  [attr.data-src]="imageUrl()"
  [hidden]="!imageUrl()"
  [alt]="alt"
  [attr.width]="width"
  [attr.height]="height"
  [ngStyle]="{ objectFit }"
  (load)="onImageLoad()"
  (error)="onImageError()"
/>
