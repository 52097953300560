import {
  EstablishmentCheckout,
  TypesOfShippings,
  firestoreDateParser,
  Feature,
  DeliveryType,
  PaymentMethod,
  ShippingOptionDelivery,
  ShippingOptionTakeAway,
  ShippingOptionInPlace,
  ShippingOptionParcel,
} from '@pedix-workspace/utils';
import { FirestoreNestedConverter, withNestedConverter } from './utils';

export type EstablishmentCheckoutConverterParams = {
  nestedConverter?: FirestoreNestedConverter<EstablishmentCheckout>;
  features: Feature[];
};

const DEFAULT_SHIPPING_OPTION_IN_PLACE: ShippingOptionInPlace = {
  active: false,
  name: 'Consumo en local',
  type: TypesOfShippings.IN_PLACE,
  allowedPaymentMethods: ['ALL'],
};
const DEFAULT_SHIPPING_OPTION_TAKE_AWAY: ShippingOptionTakeAway = {
  active: true,
  name: 'Retiro en persona',
  type: TypesOfShippings.TAKE_AWAY,
  allowedPaymentMethods: ['ALL'],
};
const DEFAULT_SHIPPING_OPTION_DELIVERY: ShippingOptionDelivery = {
  active: true,
  name: 'Envío a domicilio - Delivery',
  type: TypesOfShippings.DELIVERY,
  allowedPaymentMethods: ['ALL'],
  deliveryOperation: 'OWN',
  deliveryType: DeliveryType.TO_BE_DEFINED,
  minimumPurchaseForFreeShipping: null,
  deliveryCost: null,
  deliveryZones: null,
  outOfDeliveryZoneShippingAllowed: false,
  disableAddressInMap: false,
};
const DEFAULT_SHIPPING_OPTION_PARCEL: ShippingOptionParcel = {
  active: false,
  name: 'Envío a domicilio - Paquetería',
  type: TypesOfShippings.PARCEL,
  allowedPaymentMethods: ['ALL'],
  quotationMethod: 'INTEGRATION',
};

export const getEstablishmentCheckoutConverter = ({
  nestedConverter,
  features,
}: EstablishmentCheckoutConverterParams) =>
  withNestedConverter({
    fromFirestore(snapshot) {
      return convertEstablishmentCheckoutData(
        snapshot.id,
        <EstablishmentCheckout>snapshot.data(),
        features,
      );
    },
    toFirestore(establishment: EstablishmentCheckout): EstablishmentCheckout {
      return establishment;
    },
  })(nestedConverter);

export const convertEstablishmentCheckoutData = (
  establishmentCheckoutId: string,
  establishmentCheckoutData: Partial<EstablishmentCheckout>,
  features: Feature[],
): EstablishmentCheckout => {
  return <EstablishmentCheckout>{
    ...establishmentCheckoutData,
    id: establishmentCheckoutId,
    deliveryCost:
      establishmentCheckoutData.deliveryCost === undefined
        ? 0
        : establishmentCheckoutData.deliveryCost,
    shippingOptionInPlace: getShippingOptionInPlace(
      establishmentCheckoutData.shippingOptionInPlace,
      features,
    ),
    shippingOptionTakeAway: getShippingOptionTakeAway(
      establishmentCheckoutData.shippingOptionTakeAway,
      features,
    ),
    shippingOptionDelivery: getShippingOptionDelivery(
      establishmentCheckoutData.shippingOptionDelivery,
      features,
    ),
    shippingOptionParcel: getShippingOptionParcel(
      establishmentCheckoutData.shippingOptionParcel,
      features,
    ),
    paymentMethods: getPaymentMethods(establishmentCheckoutData.paymentMethods),
    customFields: getCustomFields(features, establishmentCheckoutData.customFields),
    created: firestoreDateParser(establishmentCheckoutData.created),
    updated: firestoreDateParser(establishmentCheckoutData.updated),
  };
};

function getShippingOptionInPlace(
  shippingOptionInPlace: ShippingOptionInPlace | undefined,
  features: Feature[],
): ShippingOptionInPlace {
  if (!shippingOptionInPlace) {
    return DEFAULT_SHIPPING_OPTION_IN_PLACE;
  }
  return shippingOptionInPlace;
}

function getShippingOptionTakeAway(
  shippingOptionTakeAway: ShippingOptionTakeAway | undefined,
  features: Feature[],
): ShippingOptionTakeAway {
  if (!shippingOptionTakeAway) {
    return DEFAULT_SHIPPING_OPTION_TAKE_AWAY;
  }
  return shippingOptionTakeAway;
}

function getShippingOptionDelivery(
  shippingOptionDelivery: ShippingOptionDelivery | undefined,
  features: Feature[],
): ShippingOptionDelivery {
  if (!shippingOptionDelivery) {
    return DEFAULT_SHIPPING_OPTION_DELIVERY;
  }
  if (
    shippingOptionDelivery.deliveryOperation === 'INTEGRATION' &&
    !features.includes('INTEGRATIONS')
  ) {
    delete shippingOptionDelivery.deliveryOperation;
  }
  if (
    shippingOptionDelivery.deliveryType === DeliveryType.DELIVERY_ZONES &&
    !features.includes('DELIVERY_ZONES')
  ) {
    delete shippingOptionDelivery.deliveryType;
  }

  if (!shippingOptionDelivery.deliveryOperation) {
    shippingOptionDelivery.deliveryOperation = 'OWN';
  }
  if (!shippingOptionDelivery.deliveryType) {
    shippingOptionDelivery.deliveryType = DeliveryType.TO_BE_DEFINED;
  }
  if (shippingOptionDelivery.deliveryType === DeliveryType.DELIVERY_ZONES) {
    shippingOptionDelivery.disableAddressInMap = false;
  }

  return shippingOptionDelivery;
}

function getShippingOptionParcel(
  shippingOptionParcel: ShippingOptionParcel | undefined,
  features: Feature[],
): ShippingOptionParcel {
  if (!features.includes('PARCEL_SHIPPING')) {
    return null;
  }
  if (!shippingOptionParcel) {
    return DEFAULT_SHIPPING_OPTION_PARCEL;
  }
  return shippingOptionParcel;
}

export const getPaymentMethods = (paymentMethods?: PaymentMethod[]) => {
  if (!paymentMethods) {
    return [];
  }
  return paymentMethods.map(paymentMethod => {
    if (!paymentMethod.priceModifier) {
      // Sets default priceModifier to 'none' and backfill existing records with 'extraCharge'
      paymentMethod.priceModifier = paymentMethod.extraCharge ? 'extraCharge' : 'none';
    }
    return paymentMethod;
  });
};

export const getCustomFields = (
  features: Feature[],
  customFields?: EstablishmentCheckout['customFields'],
): EstablishmentCheckout['customFields'] => {
  if (!customFields || !features.includes('CHECKOUT_CUSTOM_FIELDS')) {
    return [];
  }
  return customFields.map(customField => {
    if (!customField.fieldType) {
      customField.fieldType = 'text'; // default fieldType
    }
    if ((customField as any)['leyend']) {
      customField.legend = (customField as any)['leyend']; // fixes old data typo

      delete (customField as any)['leyend'];
    }
    return customField;
  });
};
