import { Injectable, inject } from '@angular/core';
import {
  GeoIpService,
  ModulePreloadManualStrategyService,
} from '@pedix-workspace/pedixapp-core-services';
import { EstablishmentsCheckoutService } from './establishments-checkout.service';
import { AppRequestContextService } from '../../app-request-context.service';
import { HereMapsLoaderService } from '@pedix-workspace/angular-ui-here-maps';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CheckoutPreloaderService {
  private modulePreloaderService = inject(ModulePreloadManualStrategyService);
  private establishmentsCheckoutService = inject(EstablishmentsCheckoutService);
  private appRequestContextService = inject(AppRequestContextService);
  private hereMapsLoaderService = inject(HereMapsLoaderService);
  private geoIpService = inject(GeoIpService);

  private checkoutPreloaded = false;

  preloadCheckout() {
    // Preloads order-checkout module and checkout data for better UX
    if (!this.checkoutPreloaded) {
      this.modulePreloaderService.preloadModuleByName('order-checkout');
      this.establishmentsCheckoutService.get(this.appRequestContextService.establishment.id);
      this.geoIpService.retrieveGeoIpState(environment.workerServicesUrl);
      this.hereMapsLoaderService.prefetchResources();

      this.checkoutPreloaded = true;
    }
  }
}
