import { marker as _ } from '@ngneat/transloco-keys-manager/marker.js';
import { OrderItem, OrderPresentationParams, OrderOptionParams } from '../order-message.types';
import {
  OrderHeadingParams,
  OrderItemsByCategories,
  OrderTotalParams,
  MessageRenderParams,
} from '../order-message.types';
import {
  translate,
  currencyFormat,
  getCurrencyLocaleDataByCountryCode,
} from '@pedix-workspace/utils';
import {
  endOrderToOrderHeadingParams,
  endOrderToOrderItemsByCategories,
  getEndOrderToOrderTotalParams,
  hasAnyPriceModifier,
} from '../order-message-utils/order-message-utils';
import { OrderMessageRendererEscPos } from '../order-message-renderer/order-message-renderer-escpos.class';
import { OrderMessageRendererBase } from '../order-message-renderer/order-message-renderer-base.class';
import * as dateFnsTz from 'date-fns-tz';
import { OrderMessageRendererWhatsapp } from '../order-message-renderer/order-message-renderer-whatsapp.class';
import { OrderMessageRendererHtml } from '../order-message-renderer/order-message-renderer-html.class';
import { GenerateOrderMessageParams } from '../generate-order-message';

export function generateOrderMessageReceipt({
  rendererType,
  orderDetails,
  establishmentData,
  renderParams,
  printerParams,
}: GenerateOrderMessageParams) {
  const orderHeadingParams = endOrderToOrderHeadingParams(
    orderDetails,
    renderParams.siteUrl,
    establishmentData.slug,
  );
  const orderItemsByCategories = endOrderToOrderItemsByCategories(orderDetails.cartItems);
  const orderTotalParams = getEndOrderToOrderTotalParams(orderDetails);

  let renderer: OrderMessageRendererBase;

  switch (rendererType) {
    case 'whatsapp':
      renderer = new OrderMessageRendererWhatsapp(renderParams);
      break;
    case 'html':
      renderer = new OrderMessageRendererHtml(renderParams);
      break;
    case 'escpos':
      renderer = new OrderMessageRendererEscPos(renderParams, printerParams);
      break;
  }

  renderEstablishmentData(renderer, establishmentData, renderParams);
  renderEmptyLine(renderer);
  renderOrderHeading(renderer, orderHeadingParams, renderParams);
  renderEmptyLine(renderer);
  renderOrderItems(renderer, orderItemsByCategories, renderParams);
  renderEmptyLine(renderer);
  renderOrderTotal(renderer, orderTotalParams, renderParams);
  renderEmptyLine(renderer);
  renderOrderFooter(renderer, orderTotalParams, renderParams);

  return renderer.output();
}

type NodeRenderer<T = unknown, R = MessageRenderParams> = {
  (renderer: OrderMessageRendererBase, options?: T, renderOptions?: R): void;
};

const renderEmptyLine: NodeRenderer = renderer => renderer.renderEmptyLine();

const renderEstablishmentData: NodeRenderer<GenerateOrderMessageParams['establishmentData']> = (
  renderer,
  { name, addressDetails },
) => {
  renderer.renderLine(name, { alignment: 'center' });

  if (addressDetails?.fullAddress) {
    renderer.renderLine(addressDetails.fullAddress, { alignment: 'center' });
  }
};

const renderOrderHeading: NodeRenderer<OrderHeadingParams> = (
  renderer,
  { id, sequenceId, slug, date, name, phone, paymentMethod, couponCode, shippingOptionType },
  { translations, timezone },
) => {
  const formattedDate = timezone
    ? dateFnsTz.formatInTimeZone(date, timezone, 'dd/MM/yy - HH:mm')
    : dateFnsTz.format(date, 'dd/MM/yy - HH:mm');

  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.orderTitle')),
    keyModifiers: { bold: true },
    valueText: `#${sequenceId} / ${id}`,
    valueModifiers: { monospace: true },
  });
  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.establishmentTitle')),
    keyModifiers: { bold: true },
    valueText: slug,
  });
  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.orderDate')),
    keyModifiers: { bold: true },
    valueText: `${formattedDate}hs`,
  });
  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.nameTitle')),
    keyModifiers: { bold: true },
    valueText: name,
  });
  renderer.renderKeyValueLine({
    keyText: translate(translations, _('orderMessage.phoneTitle')),
    keyModifiers: { bold: true },
    valueText: phone,
  });

  renderer.renderEmptyLine();

  if (paymentMethod) {
    const paymentMethodLabel = translate(translations, _('orderMessage.paymentMethodTitle'));

    renderer.renderKeyValueLine({
      keyText: paymentMethodLabel,
      keyModifiers: { bold: true },
      valueText: paymentMethod,
    });
  }
  if (couponCode) {
    const couponCodeLabel = translate(translations, _('orderMessage.couponCodeTitle'));

    renderer.renderKeyValueLine({
      keyText: couponCodeLabel,
      keyModifiers: { bold: true },
      valueText: couponCode,
    });
  }
  if (shippingOptionType) {
    const shippingOptionLabel = translate(translations, _('orderMessage.shippingOptionTitle'));
    let shippingOptionValue: string;

    switch (shippingOptionType) {
      case 'DELIVERY':
        shippingOptionValue = translate(translations, _('orderMessage.shippingDeliveryValue'));
        break;
      case 'IN_PLACE':
        shippingOptionValue = translate(translations, _('orderMessage.shippingInPlaceTitle'));
        break;
      case 'TAKE_AWAY':
        shippingOptionValue = translate(translations, _('orderMessage.shippingTakeAwayValue'));
        break;
    }

    renderer.renderKeyValueLine({
      keyText: shippingOptionLabel,
      keyModifiers: { bold: true },
      valueText: shippingOptionValue,
    });
  }
};

const renderOrderItems: NodeRenderer<OrderItemsByCategories> = (
  renderer,
  { categories },
  renderOptions,
) => {
  categories.forEach(category => {
    const isLastCategory = category === categories[categories.length - 1];

    category.groups.forEach(categoryGroup => {
      const isLastGroup = categoryGroup === category.groups[category.groups.length - 1];

      renderCategory(
        renderer,
        {
          categoryName: category.categoryName,
          groupName: categoryGroup.isCustomGroup ? categoryGroup.name : undefined,
          products: categoryGroup.items,
        },
        renderOptions,
      );
      if (!isLastCategory || !isLastGroup) {
        renderEmptyLine(renderer);
      }
    });
  });
};

const renderCategory: NodeRenderer<{
  categoryName: string;
  groupName: string;
  products: OrderItem[];
}> = (renderer, { categoryName, groupName, products }, renderOptions) => {
  const title = groupName ? `${categoryName} (${groupName})` : categoryName;

  renderer.renderLine(title, { bold: true, uppercase: true });

  products.forEach(product => renderProduct(renderer, product, renderOptions));
};

const renderProduct: NodeRenderer<OrderItem> = (
  renderer,
  { productName, presentationName, quantity, unitPrice, total, presentations, options },
  renderOptions,
) => {
  if (presentationName) {
    renderer.renderLine(`${productName} (${presentationName})`);
  } else {
    renderer.renderLine(`${productName}`);
  }

  const formattedUnitPrice = `${currencyFormat(unitPrice, getCurrencyLocaleDataByCountryCode(renderOptions.currencyCode), renderer.getCurrencyOptions())}`;
  const formattedTotalPrice = `${currencyFormat(total, getCurrencyLocaleDataByCountryCode(renderOptions.currencyCode), renderer.getCurrencyOptions())}`;

  if (presentations && presentations?.items.length > 0) {
    renderProductPresentations(renderer, presentations, { ...renderOptions, nestingLevel: 1 });
  } else {
    renderer.renderColumns(
      [
        `${renderer.getIdentationByLevel(1)}${quantity}x ${formattedUnitPrice}`,
        formattedTotalPrice,
      ],
      [2, 1],
      { monospace: true, bold: true },
    );
  }
  if (options && options.length > 0) {
    renderProductOptions(renderer, options, { ...renderOptions, nestingLevel: 1 });
  }
};

const renderProductPresentations: NodeRenderer<OrderPresentationParams> = (
  renderer,
  presentations,
  { nestingLevel, currencyCode },
) => {
  presentations.items
    .filter(item => (item.price || 0) > 0)
    .forEach(item => {
      const formattedUnitPrice = `${currencyFormat(item.unitPrice, getCurrencyLocaleDataByCountryCode(currencyCode), renderer.getCurrencyOptions())}`;
      const formattedTotalPrice = `${currencyFormat(item.price, getCurrencyLocaleDataByCountryCode(currencyCode), renderer.getCurrencyOptions())}`;

      renderer.renderLine(`${renderer.getIdentationByLevel(nestingLevel)}${item.name}`);
      renderer.renderColumns(
        [
          `${renderer.getIdentationByLevel(nestingLevel + 1)}${item.quantity}x ${formattedUnitPrice}`,
          formattedTotalPrice,
        ],
        [2, 1],
        { monospace: true },
      );
    });
};

const renderProductOptions: NodeRenderer<OrderOptionParams[]> = (
  renderer,
  options,
  { nestingLevel, currencyCode },
) => {
  options.forEach(option => {
    option.items
      .filter(item => (item.price || 0) > 0)
      .forEach(item => {
        const formattedUnitPrice = `${currencyFormat(item.unitPrice, getCurrencyLocaleDataByCountryCode(currencyCode), renderer.getCurrencyOptions())}`;
        const formattedTotalPrice = `${currencyFormat(item.price, getCurrencyLocaleDataByCountryCode(currencyCode), renderer.getCurrencyOptions())}`;

        renderer.renderLine(`${renderer.getIdentationByLevel(nestingLevel)}${item.name}`);
        renderer.renderColumns(
          [
            `${renderer.getIdentationByLevel(nestingLevel + 1)}${item.quantity}x ${formattedUnitPrice}`,
            formattedTotalPrice,
          ],
          [2, 1],
          { monospace: true },
        );
      });
  });
};

const renderOrderTotal: NodeRenderer<OrderTotalParams> = (
  renderer,
  {
    totalAmount,
    finalAmount,
    totalProductDiscount,
    couponDiscount,
    deliveryCost,
    outOfDeliveryZone,
    extraChargeAmount,
    discountAmount,
    foodBankDonationAmount,
  },
  { translations, currencyCode },
) => {
  if (
    hasAnyPriceModifier({
      totalProductDiscount,
      couponDiscount,
      deliveryCost,
      extraChargeAmount,
      discountAmount,
      foodBankDonationAmount,
    })
  ) {
    const subtotalLabel = translate(translations, _('orderMessage.subtotalTitle'));
    const subtotalValue = currencyFormat(
      totalAmount - (totalProductDiscount || 0),
      getCurrencyLocaleDataByCountryCode(currencyCode),
      renderer.getCurrencyOptions(),
    );

    renderer.renderColumns([subtotalLabel, subtotalValue], [2, 1], { monospace: true });

    if (couponDiscount) {
      const couponDiscountLabel = translate(translations, _('orderMessage.couponDiscountTitle'));
      const couponDiscountValue = currencyFormat(
        couponDiscount,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderColumns([couponDiscountLabel, `-${couponDiscountValue}`], [2, 1], {
        monospace: true,
      });
    }

    if (discountAmount) {
      const discountLabel = translate(translations, _('orderMessage.paymentMethodDiscountTitle'));
      const discountValue = currencyFormat(
        discountAmount,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderColumns([discountLabel, `-${discountValue}`], [2, 1], { monospace: true });
    }

    if (deliveryCost && !outOfDeliveryZone) {
      const deliveryCostLabel = translate(translations, _('orderMessage.deliveryCostTitle'));
      const deliveryCostValue = currencyFormat(
        deliveryCost,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderColumns([deliveryCostLabel, `+${deliveryCostValue}`], [2, 1], {
        monospace: true,
      });
    }

    if (extraChargeAmount) {
      const extraChargeLabel = translate(translations, _('orderMessage.paymentMethodExtraTitle'));
      const extraChargeValue = currencyFormat(
        extraChargeAmount,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderColumns([extraChargeLabel, `+${extraChargeValue}`], [2, 1], {
        monospace: true,
      });
    }

    // Food Bank Donation
    if (foodBankDonationAmount) {
      const foodBankDonationLabel = 'Banco Alimentos Córdoba';
      const foodBankDonationValue = currencyFormat(
        foodBankDonationAmount,
        getCurrencyLocaleDataByCountryCode(currencyCode),
        renderer.getCurrencyOptions(),
      );

      renderer.renderColumns([foodBankDonationLabel, `+${foodBankDonationValue}`], [2, 1], {
        monospace: true,
      });
    }
  }
  const finalAmountLabel = translate(translations, _('orderMessage.finalAmountTitle'));
  const finalAmountValue = currencyFormat(
    finalAmount,
    getCurrencyLocaleDataByCountryCode(currencyCode),
    renderer.getCurrencyOptions(),
  );
  const finalAmountClarification = outOfDeliveryZone
    ? translate(translations, _('orderMessage.deliveryCostClarificationTitle'))
    : '';

  renderer.renderColumns(
    [finalAmountLabel, `${finalAmountValue}${finalAmountClarification ? ' *' : ''}`],
    [2, 1],
    { uppercase: true, bold: true, monospace: true },
  );

  if (finalAmountClarification && renderer.getType() === 'whatsapp') {
    renderer.renderSystemMessage(finalAmountClarification);
  }
};

const renderOrderFooter: NodeRenderer = (renderer, options, { translations }) => {
  renderer.renderSeparator();
  renderer.renderEmptyLine();
  renderer.renderLine(`*** ${translate(translations, _('orderMessage.notAnInvoiceWarning'))} ***`, {
    alignment: 'center',
  });
};
