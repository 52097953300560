@if (label) {
  <label
    class="ui-label ui-label--{{ labelSize }}"
    [ngClass]="{ 'ui-label--required': isRequired }"
    [attr.for]="inputId"
  >
    {{ label }}
  </label>
}

<div class="ui-input__editor">
  <div class="ui-input__checkbox">
    <input
      #input
      class="ui-input__checkbox-input"
      type="checkbox"
      [checked]="isChecked"
      [value]="isChecked ? 'on' : 'off'"
      [autofocus]="autofocus"
      [readonly]="isDisabled"
      (click)="onToggle($event)"
    />
    <pxw-icon [hidden]="!isChecked" class="ui-input__checkbox-icon" icon="check" color="white" />
  </div>
  @if (legend) {
    <label
      (click)="legendToggleEnabled && onToggle($event)"
      [attr.for]="inputId"
      class="ui-input__checkbox-legend"
      [ngClass]="{
        'ui-input__checkbox-legend--read-only': isDisabled,
        'ui-input__checkbox-legend--toggle-enabled': legendToggleEnabled && !isDisabled,
      }"
    >
      <ng-container
        *ngTemplateOutlet="
          itemTemplate || defaultItemTemplate;
          context: { $implicit: option, option, onToggle, isChecked }
        "
      />
    </label>
  }
</div>

<ng-template #defaultItemTemplate let-option>{{ legend }}</ng-template>

@if (shouldDisplayErrors) {
  <div class="ui-input__error-wrapper">
    <p class="ui-input__error">{{ getErrorMessage(errorEntries[0]) }}</p>
  </div>
}

@if (helper) {
  <p class="ui-helper">
    {{ helper }}
  </p>
}

<ng-content></ng-content>
