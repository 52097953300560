/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  OnInit,
  Input,
  HostBinding,
  inject,
  OnChanges,
  ChangeDetectionStrategy,
  Injector,
  booleanAttribute,
  SimpleChanges,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import clsx from 'clsx';
import { CheckboxIsCheckedPipe } from './checkbox-is-checked.pipe';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { InputCheckboxComponent } from '../input-checkbox/input-checkbox.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  WithDefaultValidatorProps,
  composeValidatorFn,
  hasChangedAnyDefaultValidatorProps,
} from '../utils/defaultValidator';
import { CheckboxIsDisabledPipe } from './checkbox-is-disabled.pipe';
import { UiFormReactiveConfigService } from '../form-reactive-config.service';
import { IconCheckComponent, IconCloseComponent } from '@pedix-workspace/angular-ui-icons';

let INPUT_UID = 0;

export type InputCheckboxGroupLayout = 'stacked' | 'inline';
export type InputCheckboxGroupLabelSize = 'xs' | 'sm' | 'md';
export type InputCheckboxGroupValueType = any[] | undefined | null;

@Component({
  selector: 'pxw-input-checkbox-group',
  templateUrl: './input-checkbox-group.component.html',
  styleUrls: ['./input-checkbox-group.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputCheckboxGroupComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputCheckboxGroupComponent,
    },
  ],
  imports: [
    NgClass,
    InputCheckboxComponent,
    ButtonComponent,
    CheckboxIsCheckedPipe,
    CheckboxIsDisabledPipe,
    FormsModule,
    NgTemplateOutlet,
    IconCloseComponent,
    IconCheckComponent,
  ],
})
export class InputCheckboxGroupComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator, WithDefaultValidatorProps
{
  @ContentChild('itemTemplate') itemTemplate: TemplateRef<any> | undefined;

  @Input({ required: true }) name: string;
  @Input() label: string;
  @Input() labelSize: InputCheckboxGroupLabelSize = 'sm';
  @Input() helper: string;
  @Input() legend: string;
  @Input() autofocus: boolean;
  @Input() messages: { [key: string]: string } = {};
  @Input() displayErrors = false;
  @Input() labelKey: string;
  @Input() valueKey: string;
  @Input() options: any[] = [];
  @Input() layout: InputCheckboxGroupLayout = 'stacked';
  @Input() displaySelectAll = false;
  @Input() selectAllText = 'Select all';
  @Input() deselectAllText = 'Deselect all';
  @Input() disabledValues: any[];
  @Input() preserveOptionsOrder = false;
  @Input() optionAll?: any;
  @Input() legendToggleEnabled = true;
  @Input({ transform: booleanAttribute }) required?: boolean;
  @Input() minLength?: number;
  @Input() maxLength?: number;

  @Output() checkboxToggle = new EventEmitter<{ option: any; nextState: boolean }>();

  @HostBinding('class') get classNames() {
    return clsx('checkbox-group', 'ui-input', this.layout);
  }

  private injector = inject(Injector);

  protected inputId: string;
  protected value: InputCheckboxGroupValueType;
  protected isDisabled: boolean;

  private onChange: (value: InputCheckboxGroupValueType) => void;
  private onTouched: () => void;
  private onValidatorChange: () => void;
  private validatorFn: ValidatorFn | null;
  private formReactiveConfigService = inject(UiFormReactiveConfigService);

  get formControl() {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      return ngControl.control as FormControl;
    }
    return null;
  }

  get isRequired() {
    return this.formControl?.hasValidator(Validators.required) || this.required === true;
  }

  get isValid() {
    return this.formControl?.valid;
  }

  get isTouched() {
    return this.formControl?.touched;
  }

  get errorEntries(): [string, any][] {
    return Object.entries(this.formControl?.errors || {});
  }

  get shouldDisplayErrors() {
    return (this.displayErrors || this.isTouched) && !this.isValid && !this.isDisabled;
  }

  get isAllSelected() {
    return this.value?.length === this.options.length;
  }

  get parentItemTemplate() {
    return this.itemTemplate;
  }

  get optionValues() {
    if (this.valueKey) {
      return this.options.map(option => option[this.valueKey]);
    }
    return this.options;
  }

  get optionAllValue() {
    if (!this.optionAll) {
      return null;
    }
    if (this.valueKey) {
      return this.optionAll[this.valueKey];
    }
    return this.optionAll;
  }

  get isOptionAllSelected(): boolean {
    if (!this.optionAll || !this.value?.length) {
      return false;
    }

    return this.value.length === 1 && this.value[0] === this.optionAllValue;
  }

  ngOnInit(): void {
    this.inputId = `input-checkbox-group-${++INPUT_UID}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasChangedAnyDefaultValidatorProps(changes)) {
      this.validatorFn = composeValidatorFn(this);

      if (this.formControl) {
        this.formControl.updateValueAndValidity();
      }
    }
  }

  writeValue(value: InputCheckboxGroupValueType) {
    this.value = value;
  }
  registerOnChange(onChange: (value: InputCheckboxGroupValueType) => void) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validatorFn?.(control) || null;
  }
  registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  onCheckboxChecked(option: any) {
    const selectedValues = this.value ? this.value.slice(0) : [];
    const value = this.valueKey ? option[this.valueKey] : option;

    if (this.optionAll && option === this.optionAll) {
      const updatedValues = [this.optionAllValue];

      this.writeValue(updatedValues);
      this.onChange(updatedValues);
      this.onValidatorChange();
      this.onTouched();

      this.checkboxToggle.emit({ option, nextState: true });

      return;
    }

    const filteredValues = selectedValues.filter(v => v !== this.optionAllValue);
    const index = filteredValues.indexOf(value);
    const nextState = index >= 0 ? false : true;

    if (index >= 0) {
      filteredValues.splice(index, 1);
      if (filteredValues.length === 0 && this.optionAll) {
        filteredValues.push(this.optionAllValue);
      }
    } else {
      filteredValues.push(value);
    }

    const updatedValues = this.preserveOptionsOrder
      ? this.optionValues.filter(optionValue => filteredValues.includes(optionValue))
      : filteredValues;

    this.writeValue(updatedValues);
    this.onChange(updatedValues);
    this.onValidatorChange();
    this.onTouched();

    this.checkboxToggle.emit({ option, nextState });
  }

  toggleSelectAll() {
    const selectedValues = this.isAllSelected ? [] : this.optionValues;

    this.writeValue(selectedValues);
    this.onChange(selectedValues);
    this.onValidatorChange();
    this.onTouched();
  }

  protected getErrorMessage([key, data]: [string, any]) {
    return (
      this.messages?.[key] ||
      this.formReactiveConfigService.getErrorMessage(key, data, { isArray: true })
    );
  }
}
