import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { IconShippingComponent } from '@pedix-workspace/angular-ui-icons';
import { ShippingStatus } from '@pedix-workspace/utils';

@Component({
  selector: 'pxw-order-shipping-status',
  standalone: true,
  imports: [CommonModule, TagComponent, IconShippingComponent],
  templateUrl: './order-shipping-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderShippingStatusComponent {
  @Input() status: ShippingStatus | null;

  get tagInfo(): { type: any; label: string } {
    switch (this.status) {
      case 'CONFIRMED':
        return { type: 'info', label: 'Confirmado' };
      case 'CANCELLED':
        return { type: 'danger', label: 'Cancelado' };
      case 'IN_PROGRESS':
        return { type: 'warning', label: 'En camino' };
      case 'NEAR_PICKUP':
        return { type: 'info', label: 'Cerca del punto de retiro' };
      case 'PICKED_UP':
        return { type: 'success', label: 'Retirado' };
      case 'NEAR_DROPOFF':
        return { type: 'info', label: 'Llegando al destino' };
      case 'COMPLETED':
        return { type: 'success', label: '¡Listo!' };
      case null:
        return { type: 'warning', label: 'Pendiente' };
      default:
        return { type: 'default', label: 'Estado desconocido' };
    }
  }
}
